import React, { createContext, useContext, useState } from "react";
import i18next from "i18next";

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [pfp, setPfp] = useState(localStorage.getItem("pfp_url"));

  const login = (userData) => {
    setUser(userData);
    const profilePic = userData.profile_pic || null;
    setPfp(profilePic);
    localStorage.setItem("pfp_url", userData.profile_pic);
    localStorage.setItem("user", JSON.stringify(userData));
    i18next.changeLanguage(userData.user_language);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("pfp_url")
  };

  const updateUser = (user) => {
    setUser((prevUser) => ({ ...prevUser, ...user }));
  };

  return (
    <AuthContext.Provider value={{ user, updateUser, pfp, setPfp, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
