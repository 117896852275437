import { useState } from "react";
import { CheckInVehicle } from "../Forms/CheckIn/CheckInVehicleRefactor";
import { CheckInVisitor } from "../Forms/CheckIn/CheckInVisitor";
import "../../CSS/checkInTrailer.css";
import "../../CSS/global.css";
import "../../CSS/buttons.css";
import { useLanguage } from "../../Media/LanguageContext";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export const CheckIn = () => {
  const [checkForm, setForm] = useState("");
  const { english } = useLanguage();
  const { t } = useTranslation();

  const handleClose = () => {
    setForm("");
  };

  return (
    <div className="global-cont">
      <Helmet>
        <title>Forward | Check in</title>
      </Helmet>
      <div className="page-overview">
        <div className="checkin-wrapper">
          <div className="overview-header">
            <h1 className="global-h1 light-black">
              {t("page-headers.check-in")}
            </h1>
          </div>
          <div className="form-cont">
            <h4 className=" global-h4 bottom-space">
              {t("forms.type.check-in-header")}
            </h4>
            <div className="flex-1">
              <button
                id="vehicle-btn"
                onClick={() => setForm("Vehicle")}
                className="check-in-option"
              >
                {t("buttons.vehicle")}
              </button>
              <button
                onClick={() => setForm("Visitor")}
                className="check-in-option"
              >
                {t("buttons.visitor")}
              </button>
            </div>
          </div>

          {checkForm === "Vehicle" ? (
            <div className="page-mask page-mask-animation">
              <CheckInVehicle onClose={handleClose} english={t} />
            </div>
          ) : null}
          {checkForm === "Visitor" ? (
            <div className="page-mask page-mask-animation">
              <CheckInVisitor onClose={handleClose} english={t} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
