import instance from "../../Interceptor/api_instance";
import UniversalTable from "../Tables/UniversalTable";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function GeoFence() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);

  const { t } = useTranslation();

  const fetchGeoData = async () => {
    try {
      setLoading(true);
      const response = await instance.get("assets/geofence-alerts");
      setData(response.data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
    fetchGeoData();
  }, []);

  return (
    <>
      <div className="global-cont">
        <Helmet>
          <title>Forward | Geo Fence Events</title>
        </Helmet>
        <div className="page-overview">
          <div className="cont-wrapper">
            <div className="overview-header">
              <h1 className="global-h1 black">
                {t("page-headers.gps-alerts")}
              </h1>
              <h4 className="global-h4 gray">{t("page-subheaders.gps")}</h4>
            </div>
            <UniversalTable
              english={t}
              data={data}
              headers={["event_type", "event_time", "vehicle_name", "company"]}
              filters={[]}
              type="reservation"
              error={error}
              pageLoading={loading}
              user={user}
            />
          </div>
        </div>
      </div>
    </>
  );
}
