import { useEffect, useState } from "react";
import instance from "../../../Interceptor/api_instance";
import Select from "react-select";

const usStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
const mexicanStates = [
  "Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Campeche",
  "Chiapas",
  "Chihuahua",
  "Coahuila",
  "Colima",
  "Durango",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "México",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatán",
  "Zacatecas",
];

export const CheckInOutForm = ({
  formConditions,
  setFormConditions,
  trailerList,
  formTrailerData,
  setTrailerData,
  handleFormDetails,
  formTruckData,
  setTruckData,
  atiTruckList,
  jfgTruckList,
  english,
  onClose,
}) => {
  const [predefinedCustomers, setPredefinedCustomers] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {}, []);

  const atiTruckListOptions = atiTruckList?.map((truck) => ({
    value: truck.name, // What the option represents
    label: truck.name, // What displays in the dropdown
  }));

  const jfgTruckListOptions = jfgTruckList?.map((truck) => ({
    value: truck.name, // What the option represents
    label: truck.name, // What displays in the dropdown
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "4px 16px",
      border: "none",
      width: "100%", // Make sure control is full width
      borderRadius: "16px",
      background: "var(--neutral-light-purple, #f4f4ff)",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      color: "#5656b9",
      minHeight: "auto", // Override to handle padding
    }),
    // You might want to adjust the width of the menu to match the control
    container: (provided) => ({
      ...provided,
      width: "100%", // Ensure the dropdown menu is also full width
      color: "#5656b9",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#5656b9",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#F4F4FF",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      borderRadius: "24px",
      padding: "16px",
      marginTop: "10px",
    }),
  };

  const setTrailerIsATI = (e) => {};

  const selectCustomer = (name) => {};

  const fetchCustomers = async () => {};

  // Handles change from react-select
  const handleSelectReactChange = (selectedOption, setFormData, key) => {
    // Check if selectedOption is not null (it can be null if the selection is cleared)
    const value = selectedOption ? selectedOption.value : "";
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  return (
    <div className="questions-cont">
      <h2 className="global-h2 light-black">
        {formConditions.inOrOut
          ? english("forms.type.vehicle.check-in.header")
          : english("forms.type.vehicle.check-out.header")}
      </h2>
      <h4 className="global-h4 gray bottom-space">
        {english("forms.type.check-in-header")}
      </h4>
      <form id="form-1st">
        {/* Is truck carrying a trailer radio input */}
        <div className="form-group">
          <label className="input-label required">
            {english("forms.type.vehicle.input-headers.carrying-bool")}{" "}
          </label>
          <div className="input-cont">
            <fieldset className="fieldsets">
              <div className="radio-options">
                <input
                  type="radio"
                  id="yes1"
                  name="group1"
                  className="radio-input"
                  checked={formConditions.carrying}
                  onChange={() =>
                    handleFormDetails(setFormConditions, "carrying", true)
                  }
                  required
                />
                <label>{english("general.yes")}</label>
              </div>
              <div className="radio-options">
                <input
                  type="radio"
                  name="group1"
                  className="radio-input"
                  checked={formConditions.carrying === false}
                  onChange={() =>
                    handleFormDetails(setFormConditions, "carrying", false)
                  }
                />
                <label>No</label>
              </div>
            </fieldset>
          </div>
        </div>

        {/* This input displays only if it is a check in */}
        {formConditions.carrying && formConditions.inOrOut ? (
          <div className="form-group">
            <label className="input-label required">
              {english("forms.type.vehicle.input-headers.ati-bool")}{" "}
            </label>
            <div className="input-cont">
              <fieldset className="fieldsets">
                <div className="radio-options">
                  <input
                    type="radio"
                    name="group2"
                    className="radio-input"
                    checked={formTrailerData.isAtiTrailer}
                    onChange={() =>
                      handleFormDetails(setTrailerData, "isAtiTrailer", true)
                    }
                    required
                  />
                  <label>{english("general.yes")}</label>
                </div>
                <div className="radio-options">
                  <input
                    type="radio"
                    name="group2"
                    className="radio-input"
                    checked={formTrailerData.isAtiTrailer === false}
                    onChange={() =>
                      handleFormDetails(setTrailerData, "isAtiTrailer", false)
                    }
                  />
                  <label>No</label>
                </div>
              </fieldset>
            </div>
          </div>
        ) : null}

        {/* Select carrier on check-in input */}
        <div className="form-check-in">
          {formConditions.carrying ? (
            <div className="form-group">
              <label className="input-label required">
                {english("yard-tables.table-columns.carrier")}
              </label>
              <div className="input-cont">
                <select
                  value={formTrailerData.carrier}
                  onChange={(e) =>
                    handleFormDetails(setTrailerData, "carrier", e.target.value)
                  }
                  id="carrier"
                  required
                >
                  <option value="">
                    {english("forms.type.vehicle.placeholders.select-carrier")}
                  </option>
                  <option value="JFG">JFG</option>
                  <option value="ATI">ATI</option>
                  <option value="Other">
                    {english("forms.type.vehicle.placeholders.other")}
                  </option>
                </select>
              </div>
            </div>
          ) : null}
        </div>

        <div className="form-group">
          <label className="input-label required">
            {english("forms.type.vehicle.input-headers.truck-number")}{" "}
          </label>
          {formTrailerData.carrier === "ATI" && formConditions.inOrOut ? (
            <div id="checkin-truck-number" className="input-cont">
              <Select
                value={atiTruckListOptions.find(
                  (option) => option.value === formTruckData.truck_number
                )}
                onChange={(e) =>
                  handleSelectReactChange(e, setTruckData, "truck_number")
                }
                options={atiTruckListOptions}
                placeholder={english(
                  "forms.type.vehicle.placeholders.select-num"
                )}
                isClearable={true} // Allows users to clear the selected value
                isSearchable={true}
                styles={customStyles}
                unstyled={true}
                required
                isLoading={atiTruckListOptions.length === 0}
                loadingMessage={() => "Loading options, please wait..."}
              />
            </div>
          ) : null}
          {formTrailerData.carrier === "JFG" && formConditions.inOrOut ? (
            <div id="checkin-truck-number" className="input-cont">
              
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};
