import { useEffect, useState } from "react";
import instance from "../../../Interceptor/api_instance";
import { CheckInOutForm } from "./CheckInOutRefactor";

export const CheckInVehicle = ({onClose, english}) => {
    // Check in conditions
    const [formConditions, setFormConditions] = useState({
        carrying: null,
        trailerType: null,
        isAtiTrailer: false,
        country: null,
        countryState: null,
        isLoading: false,
        inOrOut: true
    });
    
    // Trailer states
    const [trailerList, setTrailerList] = useState([]);
    const [formTrailerData, setTrailerData] = useState({});
    const [customerList, setCustomerList] = useState([]);

    // Truck states
    const [formTruckData, setTruckData] = useState({
        truckBody: {},
    });
    const [atiTruckList, setAtiTruckList] = useState([]);
    const [jfgTruckList, setJfgTruckList] = useState([]);

    // Current form state
    const [currentForm, setCurrentForm] = useState(1);

    // Check in status state
    const [formSuccess, setFormSuccess] = useState(false);
    const [successTitle, setSuccessTitle] = useState("");

    useEffect(() => {
        const getTrucks = async () => {
            try {
                const response = await instance.get("assets/get-trucks-out");
                if (response.status === 200) {
                    setAtiTruckList(response.data.ati_trucks);
                    setJfgTruckList(response.data.jfg_trucks);
                }
            } catch (e) {
                window.alert(e);
            }
        };
        getTrucks();
    }, []);

    const getTrailers = () => {

    };

    const handleFormDetails = (setBody, key, value) => {
        setFormConditions((prevFormData) => ({
            ...prevFormData,
            [key]: value
        }))
    };

    const handleTrailerDetailChange = (key, value) => {
        
    };

    const handleTruckDetailChange = (key, value) => {

    };

    const findTrailer = () => {
        
    };

    const submit = () => {

    };

    return (
        <div>
            {currentForm === 1 ? (
                <CheckInOutForm 
                    formConditions={formConditions}
                    setFormConditions={setFormConditions}
                    trailerList={trailerList}
                    formTrailerData={formTrailerData}
                    setTrailerData={setTrailerData}
                    handleFormDetails={handleFormDetails}
                    formTruckData={formTruckData}
                    setTruckData={setTruckData}
                    atiTruckList={atiTruckList}
                    jfgTruckList={jfgTruckList}
                    onClose={onClose}
                    english={english}
                />
            ) : null}
        </div>
    )
}