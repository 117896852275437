import { useState, useEffect } from "react";
import instance from "../../../Interceptor/api_instance";
import { sortTrailers } from "../../Tables/Sorting/functions";
import { sortTruckList } from "../../Tables/Sorting/functions";
import { CheckIn1stForm } from "../CheckIn/CheckinCheckout";
import { CheckInTrailerF15 } from "../CheckIn/CheckInTrailerF15";
import { CheckInTruckF15 } from "../CheckIn/CheckInTruckF15";
import { CheckInPhotos } from "../CheckIn/CheckInPhotos";
import CheckoutConfirm from "../../Modals/CheckoutConfirm";

export const CheckOutVehicle = ({ onClose, setSuccess, english }) => {
  // Check out conditions
  const [trailerList, setTrailerList] = useState([]);
  const [carrying, setCarrying] = useState(null);
  const [loading, setLoading] = useState(false);

  // Trailer states
  const [trailerBody, setTrailer] = useState({});
  const [customer, setCustomer] = useState("");
  const [customerList, setCustomerList] = useState([]);
  const [dimensionOption, setDimensionOption] = useState(0);
  const [customLength, setCustomLength] = useState("");
  const [customWidth, setCustomWidth] = useState("");
  const [customHeight, setCustomHeight] = useState("");

  // Truck states
  const [truckBody, setTruck] = useState({});
  const [truckList, setTruckList] = useState([]);
  // Current form states
  const [currentForm, setCurrentForm] = useState(1);
  const [trailerForm, setTrailerForm] = useState({
    trailer_dimensions: [parseFloat(53), parseFloat(8.5), parseFloat(13.5)],
    chassis: true,
    inOutDoors: true,
    trailerFloor: true,
    sideWalls: true,
    frontWall: true,
    ceiling: true,
    refrigerator: true,
    sealCondition: true,
    trailerGarbage: true,
    trailerCleanliness: true,
    filter: true,
    humidity: true,
    view_seal: true,
    verify_seal: true,
    tug_seal: true,
    twist_turn_seal: true,
  });
  const [truckForm, setTruckForm] = useState({
    bumper: true,
    engine: true,
    tires: true,
    cabFloor: true,
    fuelTank: true,
    compartments: true,
    airTank: true,
    driveShafts: true,
    fifthWheel: true,
    exhaust: true,
    truckGarbage: true,
    truckCleanliness: true,
  });

  const [lockTruck, setLockTruck] = useState(false);
  const [confirm, setConfirm] = useState(false);

  // Images states
  const [capturedPhotos, setCapturedPhotos] = useState([]);

  const [visitor, setVisitor] = useState({});
  const [order, setOrder] = useState(true);
  const [visitorList, setVisitorList] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("user") === null) {
      window.location.href = "/login";
    }

    const fetchData = async () => {
      try {
        // Retrieve trailers only if user selects yes and list is empty
        if (carrying && trailerList.length === 0) {
          const data = await instance.get("assets/view-trailers");
          if (data.status === 200) {
            sortTrailers(data.data, setTrailerList, true, setOrder);
          }
        }
        if (visitorList.length === 0 || truckList.length === 0) {
          const [truckData, visitorData] = await Promise.all([
            instance.get("assets/view-trucks"),
            instance.get("assets/get-drivers/"),
          ]);
          if (truckData.data !== "") {
            sortTruckList(truckData.data, setTruckList);
          }
          if (visitorData.data !== "") {
            setVisitorList(visitorData.data);
          }
        }
      } catch (error) {
        window.alert("Error fetching data:", error);
      }
    };
    fetchData();
  }, [carrying]);

  const nextForm = () => {
    setCurrentForm(4);
  };

  const backForm = () => {
    setCurrentForm(1);
  };

  const checkReservation = (updatedTrailer) => {
    let reservedTruck;
    if (updatedTrailer.reservation?.truck_asset) {
      reservedTruck = {
        value: updatedTrailer.reservation.truck_asset.id,
        label: `${updatedTrailer.reservation.truck_asset.company}-${updatedTrailer.reservation.truck_asset.name}`,
      };
      handleTruckChange2(reservedTruck);
      setLockTruck(true);
    } else {
      setLockTruck(false)
    }
  };

  const handleTruckChange2 = (selectedOption) => {
    if (selectedOption) {
      const selectedTruckName = selectedOption.label;
      const selectedTruckObject = truckList.filter(
        (obj) =>
          obj.truck.carrier_name + "-" + obj.truck.truck_number ===
          selectedTruckName
      );
      setTruck((prevState) => ({
        ...selectedTruckObject[0],
      }));
    } else {
      // Handle the case when no option is selected (optional)
      setTruck({});
    }
  };

  const handleTruckChange = (selectedOption) => {
    if (selectedOption) {
      const selectedTruckId = selectedOption.value;
      const selectedTruckObject = truckList.filter(
        (obj) => obj.id === parseInt(selectedTruckId)
      );
      setTruck((prevState) => ({
        ...selectedTruckObject[0],
      }));
    } else {
      setTruck({});
    }
  };

  const handleTrailerChange = (selectedOption) => {
    if (
      trailerBody === null ||
      trailerBody.reservation === null ||
      trailerBody.reservation?.truck_asset === null
    ) {
      setLockTruck(false);
    }
    if (selectedOption) {
      const selectedTrailerId = selectedOption ? selectedOption.value : "";

      const selectedTrailerObject = trailerList.filter(
        (obj) => obj.trailer.id === parseInt(selectedTrailerId)
      );

      const trailer = selectedTrailerObject[0].trailer;

      setTrailer((prevState) => ({
        ...prevState,
        ...trailer,
      }));

      setCustomer(trailer.customer ? trailer.customer : "");

      // Pass the updated trailer directly to checkReservation
      checkReservation(trailer);
    } else {
      setTrailer({});
      setTruck({});
      setLockTruck(false);
    }
  };

  const handleTrailerPart = (part, value) => {
    setTrailerForm((prevFormData) => ({
      ...prevFormData,
      [part]: value,
    }));
  };

  const handleTruckPart = (part, value) => {
    setTruckForm((prevFormData) => ({
      ...prevFormData,
      [part]: value,
    }));
  };

  const handleVisitorChange = (selectedOption) => {
    // selectedOption will be an object like { value: 'someValue', label: 'someLabel' }
    // or it will be null if the selection is cleared

    // Extract the value from selectedOption if not null, otherwise set to an empty string
    const visitorId = selectedOption ? selectedOption.value : "";

    // Find the visitor object corresponding to the selected ID
    const visitorObject = visitorList.filter(
      (obj) => obj.visitor.id === parseInt(visitorId)
    );

    // Set the selected visitor in the state
    setVisitor(visitorObject[0].visitor);
  };
  const queryCustomers = async (e) => {
    setCustomer(e.target.value);
    try {
      if (e.target.value !== "") {
        const response = await instance.get(
          "assets/customers/" + e.target.value + "/"
        );
        if (response.status === 429) {
          window.alert("Please refrain from making too many requests.");
          window.location.reload();
        }
        setCustomerList(response.data);
      } else {
        setCustomer("");
        setCustomerList([]);
      }
    } catch (e) {
      window.alert("Error getting data: " + e);
    }
  };
  const handleCheckOutDetails = (setBody, part, value) => {
    setBody((prevFormData) => ({
      ...prevFormData,
      [part]: value,
    }));
  };

  const handleCustomer = (name) => {
    setCustomerList([]);
    setCustomer(name);
  };

  const deletePhoto = (index) => {
    const updatedCapturedPhotos = [...capturedPhotos];
    updatedCapturedPhotos.splice(index, 1);
    setCapturedPhotos(updatedCapturedPhotos);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (
      truckBody.truck_condition === "Poor" ||
      truckBody.truck_condition === "Critical"
    ) {
      window.alert(english("forms.type.vehicle.warnings.truck_condition"));
      return;
    }
    if (
      trailerBody.trailer_condition === "Poor" ||
      trailerBody.trailer_condition === "Critical"
    ) {
      window.alert(english("forms.type.vehicle.warnings.trailer_condition"));
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      capturedPhotos.forEach((photo, index) => {
        formData.append("photo", photo);
      });

      //Get dimensions of trailer. Order is length, width, height and in feet
      let dimensions = [];
      if (dimensionOption === 1) {
        dimensions = [parseFloat(53), parseFloat(8.5), parseFloat(13.5)];
      } else if (dimensionOption === 2) {
        dimensions = [parseFloat(48), parseFloat(8.5), parseFloat(13.5)];
      } else {
        dimensions = [
          parseFloat(customLength),
          parseFloat(customWidth),
          parseFloat(customHeight),
        ];
      }

      if (carrying) {
        const data = {
          ...trailerForm,
          ...truckForm,
          first_name: visitor.first_name,
          last_name: visitor.last_name,
          destination:
            truckBody.city +
            ", " +
            truckBody.countryState +
            ", " +
            truckBody.country,
          carrier_name: truckBody.truck.carrier_name,
          trailer_number: trailerBody.trailer_number,
          trailer_id: trailerBody.id,
          trailer_condition: trailerBody.trailer_condition,
          trailer_dimensions: dimensions,
          trailer_type: trailerBody.trailer_type,
          truck_number: truckBody.truck.truck_number,
          truck_condition: truckBody.truck.truck_condition,
          truck_id: truckBody.truck.id,
          odometer: truckBody.odometer || 0,
          customer: customer,
          is_loaded: trailerBody.is_loaded,
          is_seal: trailerBody.is_seal,
          seal_number: trailerBody.seal_number ? trailerBody.seal_number : null,
          generalNotes: truckBody.generalNotes ? truckBody.generalNotes : "",
          visitor_id: visitor.id,
        };

        formData.append("data", JSON.stringify(data));

        await instance.post("assets/check-out-trailer/", formData, {
          headers: {
            "Content-Type": "multipart/data-form",
          },
        });
      } else {
        const data = {
          ...truckForm,
          first_name: visitor.first_name,
          last_name: visitor.last_name,
          destination:
            truckBody.city +
            ", " +
            truckBody.countryState +
            ", " +
            truckBody.country,
          carrier_name: truckBody.truck.carrier_name,
          truck_number: truckBody.truck.truck_number,
          truck_condition: truckBody.truck.truck_condition,
          truck_id: truckBody.truck.id,
          odometer: truckBody.odometer || 0,
          generalNotes: truckBody.truck.generalNotes,
          driver_id: visitor.id,
        };

        formData.append("data", JSON.stringify(data));
        await instance.post("assets/check-out-truck/", formData, {
          headers: {
            "Content-Type": "multipart/data-form",
          },
        });
      }
      setSuccess(true);
      onClose();
    } catch (e) {
      setLoading(false);
      window.alert(e);
    }
  };

  return (
    <div>
      {currentForm === 1 ? (
        <CheckIn1stForm
          carrying={carrying}
          setCarrying={setCarrying}
          trailerList={trailerList}
          customerList={customerList}
          customer={customer}
          setCustomer={setCustomer}
          setCustomerList={setCustomerList}
          truckList={truckList}
          onClose={onClose}
          english={english}
          nextForm={nextForm}
          handleCheckInDetails={handleCheckOutDetails}
          trailerBody={trailerBody}
          setTrailerBody={setTrailer}
          truckBody={truckBody}
          setTruckBody={setTruck}
          inOrOut={false}
          handleTruckChange={handleTruckChange}
          handleTrailerChange={handleTrailerChange}
          dimensionOption={dimensionOption}
          setDimensionOption={setDimensionOption}
          visitor={visitor}
          setVisitor={setVisitor}
          handleVisitorChange={handleVisitorChange}
          visitorList={visitorList}
          setCustomWidth={setCustomWidth}
          setCustomHeight={setCustomHeight}
          setCustomLength={setCustomLength}
          lockTruck={lockTruck}
        />
      ) : null}
      {currentForm === 2 ? (
        <CheckInTruckF15
          nextForm={nextForm}
          backForm={backForm}
          english={english}
          handleTruckChange={handleTruckPart}
          truckForm={truckForm}
        />
      ) : null}
      {currentForm === 3 ? (
        <CheckInTrailerF15
          backForm={backForm}
          carrying={carrying}
          nextForm={nextForm}
          english={english}
          handleTrailerChange={handleTrailerPart}
          trailerForm={trailerForm}
          sealStatus={trailerBody.is_seal}
        />
      ) : null}
      {currentForm === 4 ? (
        <CheckInPhotos
          english={english}
          backForm={backForm}
          capturedPhotos={capturedPhotos}
          setCapturedPhotos={setCapturedPhotos}
          deletePhoto={deletePhoto}
          submit={submit}
          isLoading={loading}
          inOrOut={false}
          confirm={setConfirm}
        />
      ) : null}
      {confirm && (
        <CheckoutConfirm
          english={english}
          truck={
            truckBody.truck ? truckBody.truck.carrier_name + "-" + truckBody.truck?.truck_number : null
          }
          trailer={trailerBody? trailerBody.trailer_number : null}
          confirm={setConfirm}
          submit={submit}
          type="check out"
        />
      )}
    </div>
  );
};
