import { useEffect, useState } from "react";
import { CheckOutIcon } from "../Media/Icons";
import { CheckOutVisitor } from "./Forms/CheckOut/CheckOutVisitor";
import instance from "../Interceptor/api_instance";
import { CheckInSuccess } from "./Forms/CheckIn/CheckInSuccess";

export const GuardGraphics = ({ user, english }) => {
  const [visitors, setVisitors] = useState([]);
  const [recentActivity, setActivity] = useState([]);
  const [form, setForm] = useState(false);
  const [selectedVisitor, selectVisitor] = useState({});
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [loadingVisitors, setLoadingVisitors] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [activityError, setActivityError] = useState(null);
  const [visitorError, setVisitorsError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingActivity(true);
        setLoadingVisitors(true);

        // Initialize both requests without waiting for them to complete.
        const recentActivityRequest = instance.get("assets/recent-activity/");
        const visitorsRequest = instance.get("assets/get-visitors/");

        // Handle the recent activity request.
        recentActivityRequest
          .then((response) => {
            setLoadingActivity(false);
            if (response.data !== "") {
              setActivity(response.data);
            }
          })
          .catch((error) => {
            setActivityError(error);
            setLoadingActivity(false);
          });

        visitorsRequest
          .then((response) => {
            setLoadingVisitors(false);
            if (response.data !== "") {
              setVisitors(response.data);
            }
          })
          .catch((error) => {
            setVisitorsError(error);
            setLoadingVisitors(false);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (localStorage.getItem("user") === null) {
      window.location.href = "/login";
    } else {
      fetchData();
    }
  }, []);

  const handleClose = () => {
    setForm(false);
    selectVisitor({});
  };
  const visitorOut = (visitor) => {
    setForm(true);
    selectVisitor(visitor);
  };

  const confirm = async () => {
    setRequestLoading(true);

    const data = {
      first_name: selectedVisitor.visitor.first_name,
      last_name: selectedVisitor.visitor.last_name,
      visitor_id: selectedVisitor.visitor.id,
    };
    const response = await instance.post("assets/check-out-visitor/", data);

    if (response.status === 200) {
      setRequestLoading(false);
      setForm(false);
      setSuccess(true);
    }
    // window.location.reload();
  };
  return (
    <>
      <div className="overview-header">
        <h1 className="global-h1 light-black">
          {english("page-headers.overview")}
        </h1>
        <h4 className="global-h4 gray">
          {english("page-subheaders.welcome")}, {user.first_name}
        </h4>
      </div>
      <div className="graphics-cont">
        <div className="graphics-card">
          <h4 className="global-h4 gray">
            {english("graphics-cont.recent-activity.header")}
          </h4>
          <div className="recent-activity-cont">
            <div className="recent-activity">
              {loadingActivity ? (
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : recentActivity.length > 0 ? (
                recentActivity.map((activity, index) => (
                  <div key={index} className="activity-item">
                    <span className="global-h5 gray">
                      {activity.trailer.trailer_number}
                    </span>
                    {activity.check_in_dt !== null ? (
                      <span className="success">
                        {english("graphics-cont.recent-activity.check-in")}
                      </span>
                    ) : (
                      <span className="critical">
                        {english("graphics-cont.recent-activity.check-out")}
                      </span>
                    )}
                  </div>
                ))
              ) : null}
              {activityError && <p className="error">{activityError}</p>}
            </div>
          </div>
        </div>

        <div className="graphics-card">
          <h4 className="global-h4 gray">
            {english("graphics-cont.current-visitors")}
          </h4>
          <div className="recent-activity-cont">
            <div className="recent-activity">
              {loadingVisitors ? (
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : visitors.length > 0 ? (
                visitors
                  .filter((visitor) => visitor.visitor.category !== "Driver")
                  .map((visitor, index) => (
                    <div key={index} className="activity-item">
                      <span className="global-h5 gray">
                        {visitor.visitor.first_name} {visitor.visitor.last_name}{" "}
                        {visitor.visitor.category &&
                          `(${visitor.visitor.category})`}
                      </span>
                      {user.role !== "Safety" && (
                        <div onClick={() => visitorOut(visitor)}>
                          <CheckOutIcon color="#FF6E6E" />
                        </div>
                      )}
                    </div>
                  ))
              ) : null}
              {visitorError && <p className="error">{visitorError}</p>}
            </div>
          </div>
        </div>
        <div className="graphics-card">
          <h4 className="global-h4 gray">
            {english("graphics-cont.current-drivers")}
          </h4>
          <div className="recent-activity-cont">
            <div style={{ minHeight: "300px" }} className="recent-activity">
              {loadingVisitors ? (
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : visitors.length > 0 ? (
                visitors
                  .filter((visitor) => visitor.visitor.category === "Driver")
                  .map((visitor, index) => (
                    <div key={index} className="activity-item">
                      <span className="global-h5 gray">
                        {visitor.visitor.first_name} {visitor.visitor.last_name}{" "}
                        {visitor.visitor.category &&
                          `(${visitor.visitor.category})`}
                      </span>
                      {user.role !== "Safety" && (
                        <div onClick={() => visitorOut(visitor)}>
                          <CheckOutIcon color="#FF6E6E" />
                        </div>
                      )}
                    </div>
                  ))
              ) : null}
              {visitorError && <p className="error">{visitorError}</p>}
            </div>
          </div>
        </div>
      </div>
      {form ? (
        <div>
          <div className="page-mask page-mask-animation">
            <CheckOutVisitor
              onClose={handleClose}
              confirm={confirm}
              visitor={selectedVisitor}
              english={english}
              loading={requestLoading}
            />
          </div>
        </div>
      ) : null}
      {success && (
        <CheckInSuccess
          title={english("forms.type.success")}
          message={english("forms.type.visitor-check-out")}
        />
      )}
    </>
  );
};
