import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

import UniversalTable from "../Tables/UniversalTable";
import {
  backlogTruckHeaders,
  backlogTrailerHeaders,
} from "../Tables/TableHeaders";

import instance from "../../Interceptor/api_instance";

export default function BackLog() {
  const [user, setUser] = useState([]);
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [trailerData, setTrailerData] = useState(null);
  const [truckData, setTruckData] = useState(null);
  const [table, setTable] = useState("trailers-table");
  const [currentTrailerPage, setCurrentTrailerPage] = useState(
    "assets/backlog/trailers"
  );
  const [currentTruckPage, setCurrentTruckPage] = useState(
    "assets/backlog/trucks"
  );
  const [pageLoading, setPageLoading] = useState(false);
  const [currentTrailerPageNum, setCurrentTrailerPageNum] = useState(1);
  const [currentTruckPageNum, setCurrentTruckPageNum] = useState(1);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
    setTrailerData(null);
    fetchTrailerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrailerPage]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
    setTruckData(null);
    fetchTruckData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTruckPage]);

  const fetchTrailerData = async () => {
    try {
      setPageLoading(true);
      const trailerData = await instance.get(currentTrailerPage);
      setTrailerData(trailerData.data);
    } catch (error) {
      setError(error);
    } finally {
      setPageLoading(false);
    }
  };

  const fetchTruckData = async (url) => {
    try {
      setPageLoading(true);
      const truckData = await instance.get(currentTruckPage);
      setTruckData(truckData.data);
    } catch (error) {
      setError(error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleAdvancedSearch = (filters, type) => {
    const queryString = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null) {
        // Exclude null values
        queryString.append(key, value);
      }
    });
    if (type === "backlog trailer") {
      const url = `assets/backlog/trailers?${queryString.toString()}`;
      setCurrentTrailerPageNum(1);
      setCurrentTrailerPage(url);
    } else if (type === "backlog truck") {
      console.log("Here");
      const url = `assets/backlog/trucks?${queryString.toString()}`;
      setCurrentTruckPageNum(1);
      setCurrentTruckPage(url);
    }
  };

  const active = (e) => {
    const previous = document.getElementsByClassName("selected");
    for (let i = 0; i < previous.length; ++i) {
      previous[i].className = "tab";
    }
    e.target.className = "selected";
    setTable(e.target.id);
  };

  const handleNextPage = () => {
    if (table === "trailers-table") {
      setCurrentTrailerPageNum((prev) => prev + 1);
      const nextUrl = trailerData?.next;
      setCurrentTrailerPage(nextUrl);
    } else {
      setCurrentTruckPageNum((prev) => prev + 1);
      const nextUrl = truckData?.next;
      setCurrentTruckPage(nextUrl);
    }
  };

  const handlePreviousPage = () => {
    if (table === "trailers-table") {
      setCurrentTrailerPageNum((prev) => prev - 1);
      const prevUrl = trailerData?.previous;
      setCurrentTrailerPage(trailerData?.previous);
    } else if (table === "trucks-table") {
      setCurrentTruckPageNum((prev) => prev - 1);
      const prevUrl = truckData?.previous;
      setTruckData(prevUrl);
      setCurrentTruckPage(truckData?.previous);
    }
  };

  return (
    <div className="global-cont">
      <Helmet>
        <title>Forward | Backlog</title>
      </Helmet>
      <div className="page-overview">
        <div className="cont-wrapper">
          <div className="inventory-header">
            <h2 className="global-h1 light-black">
              {t("page-headers.backlog")}
            </h2>
          </div>
          <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
            <ul className="yard-tabs">
              <li className="selected" onClick={active} id="trailers-table">
                {t("yard-tables.tabs.trailers")}
              </li>
              <li className="tab" onClick={active} id="trucks-table">
                {t("yard-tables.tabs.trucks")}
              </li>
            </ul>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "16px",
                paddingRight: "16px",
                minHeight: "50px",
              }}
              className="page-select"
            >
              {/* Previous Button for Trailers */}
              {table === "trailers-table" && trailerData?.previous && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  onClick={
                    !pageLoading
                      ? handlePreviousPage
                      : console.log("stop clicking the button so fast")
                  }
                >
                  <path
                    d="M10.5601 15.04L4.16006 8.64C4.00006 8.48 3.92006 8.24 3.92006 8C3.92006 7.76 4.00006 7.52 4.16006 7.36L10.5601 0.960004C10.9601 0.560004 11.5201 0.560004 11.9201 0.960004C12.3201 1.36 12.3201 1.92 11.9201 2.32L6.16006 8.08L11.9201 13.84C12.3201 14.24 12.3201 14.8 11.9201 15.2C11.5201 15.44 10.8801 15.44 10.5601 15.04Z"
                    fill="#5656B9"
                  />
                </svg>
              )}
              {/* Previous Button for Trucks */}
              {table === "trucks-table" && truckData?.previous && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  onClick={
                    !pageLoading
                      ? handlePreviousPage
                      : console.log("stop clicking the button so fast")
                  }
                >
                  <path
                    d="M10.5601 15.04L4.16006 8.64C4.00006 8.48 3.92006 8.24 3.92006 8C3.92006 7.76 4.00006 7.52 4.16006 7.36L10.5601 0.960004C10.9601 0.560004 11.5201 0.560004 11.9201 0.960004C12.3201 1.36 12.3201 1.92 11.9201 2.32L6.16006 8.08L11.9201 13.84C12.3201 14.24 12.3201 14.8 11.9201 15.2C11.5201 15.44 10.8801 15.44 10.5601 15.04Z"
                    fill="#5656B9"
                  />
                </svg>
              )}
              <span>
                {table === "trailers-table"
                  ? (currentTrailerPageNum > 0 && currentTrailerPageNum)
                  : (currentTruckPageNum > 0 && currentTruckPageNum)}
              </span>
              {/* Next Button for Trailers */}
              {table === "trailers-table" && trailerData?.next && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  onClick={
                    !pageLoading
                      ? handleNextPage
                      : console.log("stop clicking the button so fast")
                  }
                >
                  <path
                    d="M5.44009 0.959997L11.8401 7.36C12.0001 7.52 12.0801 7.76 12.0801 8C12.0801 8.24 12.0001 8.48 11.8401 8.64L5.44009 15.04C5.04009 15.44 4.48009 15.44 4.08009 15.04C3.68009 14.64 3.68009 14.08 4.08009 13.68L9.84009 7.92L4.08009 2.16C3.68009 1.76 3.68009 1.2 4.08009 0.799996C4.48009 0.559997 5.12009 0.559998 5.44009 0.959997Z"
                    fill="#5656B9"
                  />
                </svg>
              )}

              {/* Next Button for Trucks */}
              {table === "trucks-table" && truckData?.next && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  onClick={
                    !pageLoading
                      ? handleNextPage
                      : console.log("stop clicking the button so fast")
                  }
                >
                  <path
                    d="M5.44009 0.959997L11.8401 7.36C12.0001 7.52 12.0801 7.76 12.0801 8C12.0801 8.24 12.0001 8.48 11.8401 8.64L5.44009 15.04C5.04009 15.44 4.48009 15.44 4.08009 15.04C3.68009 14.64 3.68009 14.08 4.08009 13.68L9.84009 7.92L4.08009 2.16C3.68009 1.76 3.68009 1.2 4.08009 0.799996C4.48009 0.559997 5.12009 0.559998 5.44009 0.959997Z"
                    fill="#5656B9"
                  />
                </svg>
              )}
            </div>
          </div>

          {table === "trailers-table" && (
            <UniversalTable
              english={t}
              data={trailerData?.results}
              headers={backlogTrailerHeaders}
              filters={[]}
              advancedSearch={true}
              handleAdvancedSearch={handleAdvancedSearch}
              type="backlog trailer"
              error={error}
              page={currentTrailerPage}
              user={user}
              pageLoading={pageLoading}
            />
          )}
          {table === "trucks-table" && (
            <UniversalTable
              english={t}
              data={truckData?.results}
              headers={backlogTruckHeaders}
              filters={[]}
              advancedSearch={true}
              handleAdvancedSearch={handleAdvancedSearch}
              type="backlog truck"
              error={error}
              page={currentTruckPage}
              user={user}
              pageLoading={pageLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
}
