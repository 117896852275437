import "../../CSS/profileSettings.css";
import "../../CSS/checkInTrailer.css";
import "../../CSS/buttons.css";
import { useState, useRef } from "react";
import { ChangePassword } from "./ChangePassword";
import { CheckInSuccess } from "./CheckIn/CheckInSuccess";
import { ProfilePhoto } from "../../Media/PlaceHolders";
import instance from "../../Interceptor/api_instance";
import { Dots } from "../LoadingAnimations/LoadingAnimations";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../Media/LanguageContext";
import { convertHeicToJpeg } from "../Utility/HeicToJpg";
import { useAuth } from "../../Auth/AuthProvider";

export const ProfileSettings = ({ user, english }) => {
  const [pwdForm, setForm] = useState(false);
  const [message, setMessage] = useState(false);
  const [formData, setFormData] = useState({ photo: null });
  const [key, setKey] = useState(0); // Key to force re-rendering
  const [isNewPhoto, setIsNewPhoto] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const { pfp, setPfp } = useAuth();

  let allow = user.role === "Guard";

  const handleClose = () => {
    setForm(false);
  };
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
  
    if (file) {
      const convertedFile = await convertHeicToJpeg(file);
  
      // Read the uploaded file as a data URL
      const reader = new FileReader();
      reader.onload = function (e) {
        // Set the uploaded photo in the state and formData
        setFormData((prevFormData) => ({
          ...prevFormData,
          photo: [e.target.result, convertedFile],
        }));
        setIsNewPhoto(true);
      };
      reader.readAsDataURL(convertedFile);
    }
  };
  

  // Function to handle upload button click
  const handleUploadClick = (e) => {
    e.preventDefault(); // Prevent form submission
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Ensure fileInputRef is not null before accessing click method
    }
  };

  const uploadPhoto = async () => {
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("photo", formData.photo[1]); // Append the file to the FormData object
      const response = await instance.post("api/upload-pfp", formDataToSend, {
        headers: {
          "Content-Type": "multipart/data-form",
        },
      });
      if (response.status === 200) {
        user.profile_pic = response.data.profile_pic;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("pfp_url", user.profile_pic);
        setPfp(user.profile_pic);
        setSaveSuccess(true);
        setIsNewPhoto(false);
        setLoading(false);
        setErrorMessage(null);
      }
    } catch (error) {
      setErrorMessage(error);
      setLoading(false);
    }
  };

  const handleCancelPfp = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      photo: null,
    }));
    setIsNewPhoto(false);
    setKey((prevKey) => prevKey + 1); // Trigger re-render by changing the key
  };
  return (
    <div key={key} className="settings-cont">
      <h2 className="global-h2 black">{english("page-headers.profile")}</h2>
      <div className="profile-pic-cont">
        <div className="driver-photo">
          {formData.photo ? (
            <img src={formData.photo[0]} alt="Uploaded" />
          ) : pfp && pfp !== "null" ? (
            <img src={pfp} alt="Profile" />
          ) : (
            <ProfilePhoto />
          )}
        </div>
        <input
          key={key} // Key to force re-rendering
          data-testid="file-input"
          className="file-input"
          type="file"
          name="photo"
          onChange={handleFileChange}
          accept="image/*, .heic"
          ref={fileInputRef} // Connect ref to the input element
        />
        <div className="profile-pic-btns">
          <button onClick={handleUploadClick} className="profile-pic-btn">
            {english("buttons.uploadPhoto")}
          </button>
        </div>
        {isNewPhoto && !loading && (
          <div className="btn-cont">
            <button onClick={handleCancelPfp} className="cancel-btn">
              {english("buttons.cancel")}
            </button>
            <button onClick={uploadPhoto} className="other-btn gap">
              {english("buttons.save")}
            </button>
          </div>
        )}
        {loading && <Dots />}
        {saveSuccess && (
          <p className="info">{english("forms.type.photo-saved")}</p>
        )}
        {errorMessage && <p className="error">{errorMessage}</p>}
      </div>
      <div className="personal-info-cont">
        <h4 className="global-h4 gray">
          {english("page-subheaders.personal-info")}
        </h4>

        <div id="flex-settings">
          <div className="info-input-cont">
            <div className="info-cont">
              <label className="global-h4 light-black">
                {english("settings.personalInfo.display")}
              </label>
              <input
                type="text"
                className="text-input"
                value={user.first_name + " " + user.last_name}
                disabled
              />
            </div>
            <div className="info-cont">
              <label className="global-h4 light-black">
                {english("settings.personalInfo.email")}
              </label>
              <input
                type="text"
                className="text-input"
                value={user.email}
                disabled
              />
            </div>
            <div className="info-cont">
              <label className="global-h4 light-black">
                {english("settings.personalInfo.password")}
              </label>
              <input
                disabled
                type="password"
                className="text-input"
                placeholder="******************"
              />
            </div>
          </div>
          {!allow ? (
            <div className="chng-pass-cont">
              <button className="change-pwd-btn" onClick={() => setForm(true)}>
                {english("buttons.change-password")}
              </button>
            </div>
          ) : null}
        </div>
        {pwdForm ? (
          <div>
            <div className="page-mask page-mask-animation">
              <ChangePassword
                onClose={handleClose}
                setMessage={setMessage}
                english={english}
              />
            </div>
          </div>
        ) : null}
        {message ? (
          <div>
            <div className="page-mask page-mask-animation">
              <CheckInSuccess
                title="Success!"
                message="You successfully changed your password."
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
